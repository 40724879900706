import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { ApolloLink } from 'apollo-link';
import gql from 'graphql-tag'
import { createApolloClient } from 'vue-cli-plugin-apollo/graphql-client'
import { InMemoryCache } from 'apollo-cache-inmemory'

import { GetGraphQLEndpoint } from '@/api'
import { AUTH_DATA } from '@/graphql/local'
import { get_jwt } from './jwt-auth';

// Install the vue plugin
Vue.use(VueApollo)

// Name of the localStorage item
export const AUTH_TOKEN = 'apollo-token'

const typeDefs = gql`
	extend type query {
		auth: Auth!
	}

	type Auth {
		logged_in: Boolean!,
		is_admin: Boolean!,
		user_index: Int,
		name: String
	}
`
const resolvers = {
	Mutation: {
		set_auth: (_, data, { cache }) => {
			cache.writeQuery({
				query: AUTH_DATA,
				data
			})
			return data
		}
	}
}

const AuthMiddleware = new ApolloLink((operation, forward) => {
	const token = get_jwt()
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  });
  return forward(operation)
});

// Config
const defaultOptions = {
	// You can use `https` for secure connection (recommended in production)
	httpEndpoint: GetGraphQLEndpoint,
	// You can use `wss` for secure connection (recommended in production)
	// Use `null` to disable subscriptions
	//wsEndpoint: process.env.VUE_APP_GRAPHQL_WS,
	// tokenName: AUTH_TOKEN,
	link: AuthMiddleware,
	// Enable Automatic Query persisting with Apollo Engine
	persisting: false,
	// Use websockets for everything (no HTTP)
	// You need to pass a `wsEndpoint` for this to work
	websocketsOnly: false,
	// Is being rendered on the server?
	ssr: false,

	// Override default apollo link
	// note: don't override httpLink here, specify httpLink options in the
	// httpLinkOptions property of defaultOptions.
	// link: myLink

	cache: new InMemoryCache(),

	typeDefs,
	resolvers

	// Override the way the Authorization header is set
	// getAuth: (tokenName) => ...

	// Additional ApolloClient options
	// apollo: { ... }

	// Client local data (see apollo-link-state)
	// clientState: {
	//   resolvers: {},
	//   defaults: {} 
	// },
}

const { apolloClient } = createApolloClient({
	...defaultOptions
})

// Create and export vue apollo provider
export const apolloProvider = new VueApollo({
	defaultClient: apolloClient,
	defaultOptions: {
		$query: {
			fetchPolicy: 'cache-first',
			// fetchPolicy: 'network-only',
		},
	},
	errorHandler (error) {
		console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message)
	},
})

export const DeleteCache = (key) => {
	const cache = apolloProvider.defaultClient.cache
	for (const cache_key in cache.data.data) {
		if (cache_key.match(new RegExp(key))) {
			cache.data.delete(cache_key)
		}
	}
}