<template>
<div	v-if="!$apollo.loading"
			id="titlebar"
			class="d-flex justify-content-between align-items-center"
			:class="{'scrolled': is_in_no_scroll_route }">
	<div class="d-flex align-items-center">
		<div id="logo-img" />
		<h5 class="text-primary d-none d-xl-block">
			หน่วยชันสูตรโรคสัตว์กลาง จุฬาลงกรณ์มหาวิทยาลัย {{ current_domain }}
		</h5>
		<h5 class="text-primary d-none d-lg-block d-xl-none">
			หน่วยชันสูตรโรคสัตว์กลาง จุฬาฯ {{ current_domain }}
		</h5>
		<h5 class="text-primary d-lg-none">
			CUVDL {{ current_domain }}
		</h5>
	</div>
	<nav v-if="auth.logged_in" class="d-flex">
		<router-link 	:to="{ name: 'main' }"
									tag="a"
									class="btn btn-transparent mr-2">
			<i class="fas fa-home btn-inner-icon"></i>
			หน้าหลัก
		</router-link>

		<template v-if="auth.is_admin">
			<router-link	:to="{name: 'submissions-list'}"
										tag="a"
										class="btn btn-transparent mr-2">
				<i class="fas fa-list btn-inner-icon"></i>
				การส่งตัวอย่าง
			</router-link>
			<router-link	:to="{name: 'admin'}"
										tag="a"
										class="btn btn-transparent mr-2">
				<i class="fas fa-tools btn-inner-icon"></i>
				บริหารระบบ
			</router-link>
		</template>

		<template v-else>
			<router-link	:to="{name: 'submissions-list'}"
										tag="a"
										class="btn btn-transparent mr-2">
				<i class="fas fa-file-invoice btn-inner-icon"></i>
				ติดตามผลการทดสอบ
			</router-link>
			<router-link	:to="{name: 'invoice-list'}"
										tag="a"
										class="btn btn-transparent mr-2">
				฿ Invoice
			</router-link>
		</template>

		<router-link	:to="{name: 'submit-samples'}"
									tag="a"
									class="btn btn-transparent mr-2">
			<i class="fas fa-vial btn-inner-icon"></i>
			{{ auth.is_admin? 'สร้างการส่งตัวอย่าง' : 'ส่งตัวอย่าง' }}
		</router-link>

		<router-link	:to="{ name: 'account' }"
									tag="a"
									class="btn btn-transparent mr-2 d-flex align-items-center">
			<div class="mr-2 auth-name ellipsis">{{ auth.name }}</div>
			<i class="fas fa-cog btn-inner-icon mr-0"></i>
		</router-link>

		<Notifications />

		<button class="btn btn-transparent btn-icon"
						@click="$emit('logout')">
			<i class="fas fa-sign-out-alt" />
		</button>
	</nav>

	<nav v-else class="d-flex">
		<router-link	:to="{ name: 'main' }"
									class="btn btn-transparent mr-2"
									exact>
			<i class="fas fa-home btn-inner-icon"></i>
			หน้าหลัก
		</router-link>
		<router-link	:to="{ name: 'services' }"
									class="btn btn-transparent mr-2">
			<i class="fas fa-microscope btn-inner-icon"></i>
			การบริการของเรา
		</router-link>
		<router-link	:to="{ name: 'org-chart' }"
									class="btn btn-transparent mr-2">
			<i class="fas fa-user-graduate btn-inner-icon"></i>
			บุคลากร
		</router-link>
		<router-link	:to="{ name: 'contact' }"
									class="btn btn-transparent mr-2">
			<i class="fas fa-phone btn-inner-icon"></i>
			ติดต่อสอบถาม
		</router-link>
		<router-link	:to="{ name: 'pathogen-bank' }"
									class="btn btn-transparent mr-2">
			<i class="fas fa-bacterium btn-inner-icon"></i>
			Pathogen Bank
		</router-link>
		<router-link	:to="{ name: 'login' }"
									tag="a"
									class="btn btn-primary ml-2 px-4">
			<i class="fas fa-sign-in-alt btn-inner-icon" />
			เข้าสู่ระบบ
		</router-link>
	</nav>


</div>
</template>

<script>
import { AUTH_DATA } from '@/graphql/local'
import Notifications from './Notifications'
import { GetCurrentDomainDisplayName } from '@/api'

export default {
	name: 'title-bar',
	components: { Notifications },
	props: {
		auth: {
			required: true
		}
	},
	data () {
		return {
			no_scroll_routes: [
				'submissions-list',
				'invoice-list'
			],
		}
	},
	computed: {
		is_in_no_scroll_route () {
			return /^admin/.test(this.$route.name) || this.no_scroll_routes.includes(this.$route.name)
		},
		current_domain () {
			return this.auth?.logged_in
				? `(${GetCurrentDomainDisplayName()})`
				: ''
		}
	},
	methods: {
		on_scroll () {
			const title_bar = document.getElementById('titlebar')
			if (window.scrollY > 20) {
				title_bar.classList.add('scrolled')   
			} else {
				title_bar.classList.remove('scrolled')
			}
		},
		set_titlebar_scroll () {
			const title_bar = document.getElementById('titlebar')
			if (this.is_in_no_scroll_route) {
				title_bar.classList.add('scrolled')
				window.onscroll = () => { }
			} else {
				title_bar.classList.remove('scrolled')
				window.onscroll = () => { this.on_scroll() }
			}
		},
	},
	watch: {
		'$route.name': function () { this.set_titlebar_scroll() },
	},
	mounted () {
		if (!this.is_in_no_scroll_route) {
			window.onscroll = () => { this.on_scroll() }
		}
	},
	apollo: {
		auth: {
			query: AUTH_DATA,
			update: data => data.auth
		}
	}
}

</script>

<style scoped lang="scss">
#titlebar {
	z-index: 999;
	position: fixed;
	top: 0; left: 0;
	width: 100vw;
	height: $titlebar-height;
	border-bottom: 1px solid transparent;
	transition: all 100ms ease-in-out;
	padding-top: 0.5em;
	padding-bottom: 0.5em;
	padding-left: $outer-padding-sm;
	padding-right: $outer-padding-sm;
	@include backdrop-blur;
	@include media-breakpoint-up(lg) {
		padding-left: $outer-padding;
		padding-right: $outer-padding;
	}
	&.scrolled {
		border-bottom: 1px solid $accent;
		height: $titlebar-height-scrolled;
		#logo-img {
			width: 40px;
			height: 40px;
		}
	}
	#logo-img {
		@include logo;
		width: 60px;
		height: 60px;
		margin-right: 15px;
		transition: all 100ms ease-in-out;
	}
}

.auth-name {
	max-width: 175px;
}

// Firefox style fixes for no backdrop-filter support
@-moz-document url-prefix() {
	#titlebar {
		background: $body-bg;
	}
}
</style>